import clsx from 'clsx';

type Column = {
  key: string;
  label: string;
};

type TableProps = {
  columns: Column[];
  data: any[]; // cada row é um objeto
};

const formatValue = (key: string, value: any) => {
  if (key === 'value') {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }
  if (key === 'status') {
    const statusClasses: Record<string, string> = {
      pending: 'bg-yellow-100 text-yellow-800',
      processing: 'bg-blue-100 text-blue-800',
      completed: 'bg-green-100 text-green-800',
      failed: 'bg-red-100 text-red-800',
    };
    const statusLabels: Record<string, string> = {
      pending: 'Pendente',
      processing: 'Em Processamento',
      completed: 'Concluído',
      failed: 'Falhou',
    };
    return (
      <span
        className={clsx(
          'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
          statusClasses[value]
        )}
      >
        {statusLabels[value]}
      </span>
    );
  }
  return value;
};

export const Table = ({ columns, data }: TableProps) => (
  <table className="min-w-full divide-y divide-gray-200">
    <thead className="bg-gray-50">
      <tr>
        {columns.map((column:any) => (
          <th
            key={column}
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
          >
            {column}
          </th>
        ))}
      </tr>
    </thead>
    <tbody className="bg-white divide-y divide-gray-200">
      {data.map((row, rowIndex) => (
        <tr
          key={"row_" + rowIndex}
          className="hover:bg-gray-50 transition-colors duration-150"
        >
          {row.map((column:any, colIndex:any) => (
            
            <td
              key={`row_${rowIndex}_col_${colIndex}`}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
            >
            {column}  
              
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);
