import React from 'react';
import { Bell } from 'lucide-react';

type Option = {
  value: string;
  label: string;
};

type NotificationTypeSelectProps = {
  value: string[];
  onChange: (value: string[]) => void;
  options: Option[];
};

export function NotificationTypeSelect({ value, onChange, options }: NotificationTypeSelectProps) {
  const handleChange = (optionValue: string) => {
    if (value.includes(optionValue)) {
      onChange(value.filter(v => v !== optionValue));
    } else {
      onChange([...value, optionValue]);
    }
  };

  return (
    <div className="space-y-2">
      <label className="flex items-center gap-2 text-sm font-medium text-gray-700">
        <Bell className="w-4 h-4" />
        <span>Tipos de Alerta</span>
      </label>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        {options.map(option => (
          <label
            key={option.value}
            className="relative flex items-center p-3 rounded-lg border border-gray-200 hover:bg-gray-50 cursor-pointer group"
          >
            <input
              type="checkbox"
              checked={value.includes(option.value)}
              onChange={() => handleChange(option.value)}
              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span className="ml-3 text-sm text-gray-600 group-hover:text-gray-900">
              {option.label}
            </span>
          </label>
        ))}
      </div>
    </div>
  );
}