import React, { useState, useEffect } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import { Bell, Plus, Edit2, Phone, User, X } from 'lucide-react';
import { ContactModal } from './ContactModal';
import * as API from '../../api';

export const NOTIFICATION_TYPES = [
  { value: 'dataset_unificado_powerbi', label: 'Dataset Unificado do PowerBI' },
  { value: 'relatorio_mensal_gerado', label: 'Relatórios mensais gerados' }
];

type NotificationContact = {
  id: string;
  name: string;
  phone: string;
  types: string[];
};

export default function SettingsScreen() {
  const [contacts, setContacts] = useState<NotificationContact[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedContact, setSelectedContact] = useState<NotificationContact | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    loadContacts();
  }, []);

  const loadContacts = async () => {
    setIsLoading(true);
    try {
      /*const response = await API.getNotificationContacts();
      setContacts(response.data || []);*/
    } catch (error) {
      console.error('Error loading contacts:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddContact = () => {
    setSelectedContact({
      id: crypto.randomUUID(),
      name: '',
      phone: '',
      types: []
    });
    setIsModalOpen(true);
  };

  const handleEditContact = (contact: NotificationContact) => {
    setSelectedContact(contact);
    setIsModalOpen(true);
  };

  const handleSaveContact = async (contact: NotificationContact) => {
    const newContacts = contacts.some(c => c.id === contact.id)
      ? contacts.map(c => c.id === contact.id ? contact : c)
      : [...contacts, contact];
    
    try {
      /*await API.updateNotificationContacts(newContacts);
      setContacts(newContacts);
      setIsModalOpen(false);
      setSelectedContact(null);*/
    } catch (error) {
      console.error('Error saving contact:', error);
    }
  };

  const handleDeleteContact = async (id: string) => {
    try {
      const newContacts = contacts.filter(c => c.id !== id);
      //await API.updateNotificationContacts(newContacts);
      setContacts(newContacts);
    } catch (error) {
      console.error('Error deleting contact:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-8">
          <PageTitle>Configurações</PageTitle>
        </div>

        {/* Notification Settings */}
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
          <div className="px-6 py-4 bg-gradient-to-r from-blue-600 to-blue-700 flex items-center justify-between">
            <div className="flex items-center">
              <div className="p-2 bg-white/10 rounded-lg">
                <Bell className="w-5 h-5 text-white" />
              </div>
              <h2 className="ml-3 text-lg font-semibold text-white">
                Notificações
              </h2>
            </div>
            <button
              onClick={handleAddContact}
              className="px-4 py-2 bg-white/10 text-white rounded-lg hover:bg-white/20 transition-colors inline-flex items-center gap-2"
            >
              <Plus className="w-4 h-4" />
              Adicionar Contato
            </button>
          </div>

          <div className="p-6">
            <div className="space-y-6">
              <p className="text-sm text-gray-600">
                Gerencie os contatos que receberão alertas do sistema.
              </p>

              {/* Contact List */}
              <div className="overflow-hidden rounded-lg border border-gray-200">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Nome
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Telefone
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Alertas Configurados
                      </th>
                      <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Ações
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {contacts.map(contact => (
                      <tr key={contact.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <User className="w-4 h-4 text-gray-400 mr-2" />
                            <span className="text-sm text-gray-900">{contact.name}</span>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <Phone className="w-4 h-4 text-gray-400 mr-2" />
                            <span className="text-sm text-gray-900">{contact.phone}</span>
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex flex-wrap gap-2">
                            {contact.types.map(type => {
                              const typeConfig = NOTIFICATION_TYPES.find(t => t.value === type);
                              return (
                                <span
                                  key={type}
                                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                                >
                                  {typeConfig?.label || type}
                                </span>
                              );
                            })}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right">
                          <button
                            onClick={() => handleEditContact(contact)}
                            className="p-2 text-gray-400 hover:text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
                          >
                            <Edit2 className="w-5 h-5" />
                          </button>
                          <button
                            onClick={() => handleDeleteContact(contact.id)}
                            className="p-2 text-gray-400 hover:text-red-600 hover:bg-red-50 rounded-lg transition-colors ml-2"
                          >
                            <X className="w-5 h-5" />
                          </button>
                        </td>
                      </tr>
                    ))}
                    {contacts.length === 0 && (
                      <tr>
                        <td colSpan={4} className="px-6 py-8 text-center text-gray-500">
                          Nenhum contato cadastrado
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Modal */}
      <ContactModal
        isOpen={isModalOpen}
        contact={selectedContact}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedContact(null);
        }}
        onSave={handleSaveContact}
      />
    </div>
  );
}