/* eslint-disable react/jsx-no-target-blank */

import React, { useState, useEffect } from 'react';
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { AddMenuItemModal } from '../../../partials/modals/add-menu-item/AddMenuItemModal';
import * as API from '../../../../app/api';
import styled from '@emotion/styled';
import { isSuperAdmin, getUserData, GetPermissions } from '../../../../app/modules/auth';

const StyledMenu = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 12px;
	height: 100%;

	.btn-add-container {
		padding: 16px;
	}

	.text-center {
		color:#d8d8d8;
		text-align:center;
	}

	.menu-item-border {
		border-bottom: 1px solid #ccc!important;
	}

	p {
		color:#d8d8d8;
		text-align:center;
	}
`

export function AsideMenuMain() {
	const permissions = GetPermissions();
	
	const [menu, setMenu] = useState<any[]|undefined>(undefined);
	
	async function fetchMenu(force:boolean = false) {
		if (!force && menu && menu.length) return;
		const response = await API.getMenu();
		setMenu(response.data);
	}	

	function renderMenu(item:any) {

		if (!item) return null;

		if (item.section) {
			return <>
			<div className='menu-item'>
				<div className='menu-content pt-8 pb-2 text-center'>
					<span className='menu-section text-muted text-uppercase fs-8 ls-1'>{ item.section }</span>
				</div>
			</div>
			{ item.children && item.children.map((x:any, i:number) => renderMenu(x) )}
			</>
		}

		const MenuElement = item.children && item.children.length > 0 ? AsideMenuItemWithSub : AsideMenuItem;

		if (item.label) {
			return <MenuElement
			key={item.id}
			to={item.to}
			title={item.label}
			fontIcon={item.fontIcon}
			icon={item.icon}
			hasBullet={item.hasBullet}
			>
				{ item.children && item.children.map((x:any, i:number) => renderMenu(x)) }
			</MenuElement>
		}
	}

	return <StyledMenu>
		<div className='pt-4'>
			{ permissions?.some(feature => feature.name.includes('cashback-') && feature.name.includes('view')) ? 
			<AsideMenuItemWithSub to='cashback' icon='/media/icons/duotune/menu/cashback.svg' title={'Cashback'} fontIcon='bi-app-indicator'>
				{ permissions?.some(feature => feature.name.includes('cashback-lojas-participantes-view')) ? 
				<AsideMenuItem to='/lojas' icon='/media/icons/duotune/menu/store.svg' title={'Lojas Participantes'} fontIcon='bi-app-indicator'/> : null }

				{ permissions?.some(feature => feature.name.includes('cashback-listas-view')) ? 
				<AsideMenuItem to='/listas' icon='/media/icons/duotune/menu/lists2.svg' title={'Listas'} fontIcon='bi-app-indicator'/> : null }

				{ permissions?.some(feature => feature.name.includes('cashback-regionais-view')) ? 
				<AsideMenuItem to='/regionais' icon='/media/icons/duotune/menu/icon-regional.svg' title={'Regionais'} fontIcon='bi-app-indicator'/> : null }

				{ permissions?.some(feature => feature.name.includes('cashback-tamanhos-view')) ? 
				<AsideMenuItem to='/tamanhos' icon='/media/icons/duotune/menu/ruler.svg' title={'Tamanho'} fontIcon='bi-app-indicator'/> : null }

				{ permissions?.some(feature => feature.name.includes('cashback-bus-view')) ? 
				<AsideMenuItem to='/bus' icon='/media/icons/duotune/menu/building.svg' title={'BU'} fontIcon='bi-app-indicator'/> : null }

				{ permissions?.some(feature => feature.name.includes('cashback-tabela-valores-view')) ? 
				<AsideMenuItem to='/precos' icon='/media/icons/duotune/menu/document-sheet.svg' title={'Tabela de Valores'} fontIcon='bi-app-indicator'/> : null }

				{ permissions?.some(feature => feature.name.includes('cashback-cupons-view')) ? 
				<AsideMenuItem to='/cupons' icon='/media/icons/duotune/menu/coupon2.svg' title={'Cupons'} fontIcon='bi-app-indicator'/> : null }
				
				{ permissions?.some(feature => feature.name.includes('cashback-relatorios')) ? 
				<AsideMenuItemWithSub to='/relatorios' icon='/media/icons/duotune/menu/document.svg' title={'Relatórios'} fontIcon='bi-app-indicator'>
					{ permissions?.some(feature => feature.name.includes('cashback-relatorios-positivacao')) ? 
					<AsideMenuItem to='/relatorios/positivacao' icon='/media/icons/duotune/menu/icon-positivacao.svg' title={'Positivação'} fontIcon='bi-app-indicator'/> : null }

					{ permissions?.some(feature => feature.name.includes('cashback-relatorios-ativacao')) ? 
					<AsideMenuItem to='/relatorios/ativacao' icon='/media/icons/duotune/menu/icon-ativacao.svg' title={'Ativação'} fontIcon='bi-app-indicator'/> : null }

					{ permissions?.some(feature => feature.name.includes('cashback-relatorios-lojistas')) ? 
					<AsideMenuItem to='/relatorios/lojistas' icon='/media/icons/duotune/menu/store.svg' title={'Lojistas'} fontIcon='bi-app-indicator'/> : null }

					{ permissions?.some(feature => feature.name.includes('cashback-relatorios-cupons')) ? 
					<AsideMenuItem to='/relatorios/cupons' icon='/media/icons/duotune/menu/coupon2.svg' title={'Cupons'} fontIcon='bi-app-indicator'/> : null }

					{ permissions?.some(feature => feature.name.includes('cashback-relatorios-apuracao')) ? 
					<AsideMenuItem to='/relatorios/apuracao' icon='/media/icons/duotune/menu/apuracao3.svg' title={'Apuração'} fontIcon='bi-app-indicator'/> : null }
				</AsideMenuItemWithSub>: null }
				{ permissions?.some(feature => feature.name === 'cashback-alert-view') ? 
				<AsideMenuItem to='/alerts' icon='/media/icons/duotune/menu/icon-alerts.svg' title={'Alertas'} fontIcon='bi-app-indicator'/> : null }
			</AsideMenuItemWithSub>	: null }

			{ permissions?.some(feature => feature.name.includes('conecta-')) ? 
			<AsideMenuItemWithSub to='' icon='/media/icons/duotune/menu/conecta/conecta.svg' title={'Conecta'} fontIcon='bi-app-indicator'>
				{ permissions?.some(feature => feature.name.includes('conecta-extracao-')) ? 
				<AsideMenuItemWithSub to='' icon='/media/icons/duotune/menu/conecta/extract.svg' title={'Extração'} fontIcon='bi-app-indicator'>

					{ permissions?.some(feature => feature.name.includes('conecta-extracao-sellers-')) ? 
					<AsideMenuItemWithSub to='' icon='/media/icons/duotune/menu/conecta/seller.svg' title={'Sellers'} fontIcon='bi-app-indicator'>
						{ permissions?.some(feature => feature.name.includes('conecta-extracao-sellers-sellers-view')) ? 
						<AsideMenuItem to='/conecta/extracao/seller' icon='/media/icons/duotune/menu/conecta/seller.svg' title={'Seller'} fontIcon='bi-app-indicator'/> : null }

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-sellers-seller-group-view')) ? 
						<AsideMenuItem to='/conecta/extracao/seller-group' icon='/media/icons/duotune/menu/conecta/seller-group.svg' title={'Seller Group'} fontIcon='bi-app-indicator'/> : null }

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-sellers-seller-mapping-view')) ? 
						<AsideMenuItem to='/conecta/extracao/seller-mapping' icon='/media/icons/duotune/menu/conecta/seller-mapping.svg' title={'Seller Mapping'} fontIcon='bi-app-indicator'/> : null }

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-sellers-blocklist-seller-view')) ? 
						<AsideMenuItem to='/conecta/extracao/blocklist-seller' icon='/media/icons/duotune/menu/conecta/blocklist-seller.svg' title={'Blocklist Seller'} fontIcon='bi-app-indicator'/> : null }
						
					</AsideMenuItemWithSub> : null }



					{ permissions?.some(feature => feature.name.includes('conecta-extracao-pedidos-view')) ? 
					<AsideMenuItem to='/conecta/extracao/pedidos' icon='/media/icons/duotune/menu/conecta/pedidos.svg' title={'Pedidos'} fontIcon='bi-app-indicator'/> : null }		



					{ permissions?.some(feature => feature.name.includes('conecta-extracao-lojista-')) ? 
					<AsideMenuItemWithSub to='' icon='/media/icons/duotune/menu/conecta/lojista-customer.svg' title={'Lojista'} fontIcon='bi-app-indicator'>

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-lojista-customer-view')) ? 
						<AsideMenuItem to='/conecta/extracao/lojista-customer' icon='/media/icons/duotune/menu/conecta/lojista-customer.svg' title={'Customer'} fontIcon='bi-app-indicator'/> : null }

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-lojista-company-view')) ? 
						<AsideMenuItem to='/conecta/extracao/lojista-company' icon='/media/icons/duotune/menu/conecta/lojista-company.svg' title={'Company'} fontIcon='bi-app-indicator'/> : null }

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-lojista-ul-ponderadas-view')) ? 
						<AsideMenuItem to='/conecta/extracao/lojista-ul-ponderadas' icon='/media/icons/duotune/menu/conecta/ul-ponderada.svg' title={'UL Ponderadas'} fontIcon='bi-app-indicator'/> : null }

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-lojista-vip-view')) ? 
						<AsideMenuItem to='/conecta/extracao/lojista-vip' icon='/media/icons/duotune/menu/conecta/vip.svg' title={'VIP'} fontIcon='bi-app-indicator'/> : null }

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-lojista-grupo-lojista-root-view')) ? 
						<AsideMenuItem to='/conecta/extracao/grupo-lojista-root' icon='/media/icons/duotune/menu/conecta/grupo-lojista-root.svg' title={'Grupo Lojista - Root'} fontIcon='bi-app-indicator'/> : null }

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-lojista-grupo-lojista-open-view')) ? 
						<AsideMenuItem to='/conecta/extracao/grupo-lojista-open' icon='/media/icons/duotune/menu/conecta/grupo-lojista-open.svg' title={'Grupo Lojista - Open'} fontIcon='bi-app-indicator'/> : null }

					</AsideMenuItemWithSub> : null }




					{ permissions?.some(feature => feature.name.includes('conecta-extracao-vendedor-')) ? 
					<AsideMenuItemWithSub to='' icon='/media/icons/duotune/menu/conecta/vendedor.svg' title={'Vendedor'} fontIcon='bi-app-indicator'>

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-vendedor-vendedor-view')) ? 
						<AsideMenuItem to='/conecta/extracao/vendedor' icon='/media/icons/duotune/menu/conecta/vendedor.svg' title={'Vendedor'} fontIcon='bi-app-indicator'/> : null }

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-vendedor-grupo-vendedor-view')) ? 
						<AsideMenuItem to='/conecta/extracao/grupo-vendedor' icon='/media/icons/duotune/menu/conecta/grupo-vendedor.svg' title={'Grupo Vendedor'} fontIcon='bi-app-indicator'/> : null }
					</AsideMenuItemWithSub> : null }


					

					{ permissions?.some(feature => feature.name.includes('conecta-extracao-limite-credito-view')) ? 
					<AsideMenuItem to='/conecta/extracao/limite-credito' icon='/media/icons/duotune/menu/conecta/credit-limit.svg' title={'Limite de Crédito'} fontIcon='bi-app-indicator'/> : null }

					

					{ permissions?.some(feature => feature.name.includes('conecta-extracao-vouchers-')) ? 
					<AsideMenuItemWithSub to='' icon='/media/icons/duotune/menu/conecta/voucher-primario.svg' title={'Vouchers'} fontIcon='bi-app-indicator'>

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-vouchers-primario-view')) ? 
						<AsideMenuItem to='/conecta/extracao/vouchers-primario' icon='/media/icons/duotune/menu/conecta/voucher-primario.svg' title={'Vouchers Primário'} fontIcon='bi-app-indicator'/> : null }

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-vouchers-secundario-view')) ? 
						<AsideMenuItem to='/conecta/extracao/vouchers-secundario' icon='/media/icons/duotune/menu/conecta/voucher-secundario.svg' title={'Vouchers Secundário'} fontIcon='bi-app-indicator'/> : null }
					
					</AsideMenuItemWithSub>	: null }

					
					

					{ permissions?.some(feature => feature.name.includes('conecta-extracao-produto-')) ? 
					<AsideMenuItemWithSub to='' icon='/media/icons/duotune/menu/conecta/produto.svg' title={'Produto'} fontIcon='bi-app-indicator'>

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-produto-produto-view')) ? 
						<AsideMenuItem to='/conecta/extracao/produto' icon='/media/icons/duotune/menu/conecta/produto.svg' title={'Produto'} fontIcon='bi-app-indicator'/> : null }

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-produto-marca-view')) ? 
						<AsideMenuItem to='/conecta/extracao/marca' icon='/media/icons/duotune/menu/conecta/marca.svg' title={'Marca'} fontIcon='bi-app-indicator'/> : null }

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-produto-categoria-view')) ? 
						<AsideMenuItem to='/conecta/extracao/categoria' icon='/media/icons/duotune/menu/conecta/categoria.svg' title={'Categoria'} fontIcon='bi-app-indicator'/> : null }

					</AsideMenuItemWithSub>	: null }
					


					{ permissions?.some(feature => feature.name.includes('conecta-extracao-industrias-')) ? 
					<AsideMenuItemWithSub to='' icon='/media/icons/duotune/menu/conecta/industria.svg' title={'Indústrias'} fontIcon='bi-app-indicator'>

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-industrias-industrias-view')) ? 
						<AsideMenuItem to='/conecta/extracao/industria' icon='/media/icons/duotune/menu/conecta/industria.svg' title={'Indústria'} fontIcon='bi-app-indicator'/> : null }

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-industrias-blocklist-industria-view')) ? 
						<AsideMenuItem to='/conecta/extracao/blocklist-industria' icon='/media/icons/duotune/menu/conecta/blocklist-industria.svg' title={'Blacklist Indústria'} fontIcon='bi-app-indicator'/> : null }

					</AsideMenuItemWithSub>	: null }


					{ permissions?.some(feature => feature.name.includes('conecta-extracao-devit-')) ? 
					<AsideMenuItemWithSub to='' icon='/media/icons/duotune/menu/conecta/devit.svg' title={'DevIT'} fontIcon='bi-app-indicator'>

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-devit-distribuidores-view')) ? 
						<AsideMenuItem to='/conecta/extracao/devit-distribuidores' icon='/media/icons/duotune/menu/conecta/devit-distribuidores.svg' title={'Distribuidores'} fontIcon='bi-app-indicator'/> : null }
						
						{ permissions?.some(feature => feature.name.includes('conecta-extracao-devit-vendedores-view')) ? 
						<AsideMenuItem to='/conecta/extracao/devit-vendedores' icon='/media/icons/duotune/menu/conecta/devit-vendedores.svg' title={'Vendedores'} fontIcon='bi-app-indicator'/> : null }
						
						{ permissions?.some(feature => feature.name.includes('conecta-extracao-devit-lojistas-view')) ? 
						<AsideMenuItem to='/conecta/extracao/devit-lojistas' icon='/media/icons/duotune/menu/conecta/devit-lojistas.svg' title={'Lojistas'} fontIcon='bi-app-indicator'/> : null }

						

					</AsideMenuItemWithSub>	: null }


					{ permissions?.some(feature => feature.name.includes('conecta-extracao-freshdesk-')) ? 
					<AsideMenuItemWithSub to='' icon='/media/icons/duotune/menu/conecta/freshdesk.svg' title={'Freshdesk'} fontIcon='bi-app-indicator'>

						{ permissions?.some(feature => feature.name.includes('conecta-extracao-freshdesk-lojista-company-view')) ? 
						<AsideMenuItem to='/conecta/extracao/freshdesk-lojista-company' icon='/media/icons/duotune/menu/conecta/freshdesk-lojista-company.svg' title={'Lojista Company'} fontIcon='bi-app-indicator'/> : null }
						
						{ permissions?.some(feature => feature.name.includes('conecta-extracao-freshdesk-lojista-contact-view')) ? 
						<AsideMenuItem to='/conecta/extracao/freshdesk-lojista-contact' icon='/media/icons/duotune/menu/conecta/freshdesk-lojista-contact.svg' title={'Lojista Contact'} fontIcon='bi-app-indicator'/> : null }
						
						{ permissions?.some(feature => feature.name.includes('conecta-extracao-freshdesk-vendedor-view')) ? 
						<AsideMenuItem to='/conecta/extracao/freshdesk-vendedor' icon='/media/icons/duotune/menu/conecta/freshdesk-vendedor.svg' title={'Vendedor'} fontIcon='bi-app-indicator'/> : null }
						
						{ permissions?.some(feature => feature.name.includes('conecta-extracao-freshdesk-distribuidor-view')) ? 
						<AsideMenuItem to='/conecta/extracao/freshdesk-distribuidor' icon='/media/icons/duotune/menu/conecta/freshdesk-distribuidor.svg' title={'Distribuidor'} fontIcon='bi-app-indicator'/> : null }

						

					</AsideMenuItemWithSub>	: null }

				</AsideMenuItemWithSub>	: null }
				
				{ permissions?.some(feature => feature.name.includes('conecta-diferencas-')) ? 
				<AsideMenuItemWithSub to='/diferencas' icon='/media/icons/duotune/menu/conecta/diferencas.svg' title={'Diferenças'} fontIcon='bi-app-indicator'>
					{ permissions?.some(feature => feature.name.includes('conecta-diferencas-conecta-x-ifc-view')) ? 
					<AsideMenuItem to='' icon='/media/icons/duotune/menu/conecta/conecta-x-ifc.svg' title={'Conecta x IFC'} fontIcon='bi-app-indicator'/> : null }
				</AsideMenuItemWithSub> : null }
			</AsideMenuItemWithSub> : null }


			{ permissions?.some(feature => feature.name === 'logs-view') ? 
				<AsideMenuItem to='/logs' icon='/media/icons/duotune/menu/icon-logs.svg' title={'Logs'} fontIcon='bi-app-indicator'/>: null }
			
			{ permissions?.some(feature => feature.name === 'ai-view') ? <AsideMenuItem to='/ai' icon='/media/icons/duotune/menu/robot-ai-svgrepo-com.svg' title={'Compra AI'} fontIcon='bi-app-indicator'/> : null }
			
			{ permissions?.some(feature => feature.name === 'campaign-simulator-view') ? <AsideMenuItem to='/campaigns' icon='/media/icons/duotune/menu/campaign.svg' title={'Simulador de Campanhas'} fontIcon='bi-app-indicator'/> : null }
			
			{ isSuperAdmin() ? <AsideMenuItem to='/permissions' icon='/media/icons/duotune/menu/permissions2.svg' title={'Permissões'} fontIcon='bi-app-indicator'/> : null }
			{ isSuperAdmin() ? <AsideMenuItem to='/settings' icon='/media/icons/duotune/menu/settings.svg' title={'Configurações'} fontIcon='bi-app-indicator'/> : null }
			
			
		</div>
		
		{ !!menu && menu.map((item, i) => <div key={'section' + i}>{renderMenu(item)}</div>) }
		<div className="space"></div>
		{ menu && !menu.length && <p className='mt-4'>Menu indisponível</p> }
	</StyledMenu>
}