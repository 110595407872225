import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Save, Loader2, User } from 'lucide-react';
import { PhoneInput } from './PhoneInput';
import { NotificationTypeSelect } from './NotificationTypeSelect';
import { NOTIFICATION_TYPES } from './index';

type NotificationContact = {
  id: string;
  name: string;
  phone: string;
  types: string[];
};

type ContactModalProps = {
  isOpen: boolean;
  contact: NotificationContact | null;
  onClose: () => void;
  onSave: (contact: NotificationContact) => Promise<void>;
};

export function ContactModal({ isOpen, contact, onClose, onSave }: ContactModalProps) {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [types, setTypes] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (contact) {
      setName(contact.name);
      setPhone(contact.phone);
      setTypes(contact.types);
    } else {
      setName('');
      setPhone('');
      setTypes([]);
    }
  }, [contact]);

  const handleSave = async () => {
    if (!contact) return;
    
    setIsSaving(true);
    try {
      await onSave({
        ...contact,
        name,
        phone,
        types
      });
    } finally {
      setIsSaving(false);
    }
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-screen items-center justify-center p-4">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50"
            onClick={onClose}
          />

          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="relative bg-white rounded-2xl shadow-xl max-w-lg w-full overflow-hidden"
          >
            {/* Header */}
            <div className="px-6 py-4 bg-gradient-to-r from-blue-600 to-blue-700 flex items-center justify-between">
              <h3 className="text-lg font-semibold text-white">
                {contact?.phone ? 'Editar Contato' : 'Novo Contato'}
              </h3>
              <button
                onClick={onClose}
                className="p-2 text-white/80 hover:text-white hover:bg-white/10 rounded-lg transition-colors"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            {/* Content */}
            <div className="p-6 space-y-6">
              {/* Name Input */}
              <div className="relative">
                <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Nome do contato"
                  className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <PhoneInput value={phone} onChange={setPhone} />

              <NotificationTypeSelect
                value={types}
                onChange={setTypes}
                options={NOTIFICATION_TYPES}
              />

              {/* Footer */}
              <div className="flex justify-end pt-6 border-t border-gray-200">
                <button
                  onClick={onClose}
                  className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors mr-3"
                >
                  Cancelar
                </button>
                <button
                  onClick={handleSave}
                  disabled={!name || !phone || isSaving}
                  className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isSaving ? (
                    <>
                      <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                      <span>Salvando...</span>
                    </>
                  ) : (
                    <>
                      <Save className="w-5 h-5 mr-2" />
                      <span>Salvar</span>
                    </>
                  )}
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </AnimatePresence>
  );
}