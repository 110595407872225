import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FileSpreadsheet, Search, Loader2, Filter, Calendar } from 'lucide-react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as API from '../../../api';
import { PageTitle } from '../../../../_metronic/layout/core';
import { Modal } from 'react-bootstrap';
import ConfirmModal from '../../modals/ConfirmModal';
import DownloadModal from '../../modals/DownloadModal';
import { DataTable } from './DataTable';
import { StatsCard } from './StatsCard';
import { SearchInput } from './SearchInput';

const limit = 250;

const STATUS_OPTIONS = [
  { value: '1', label: 'Ativo' },
  { value: '0', label: 'Inativo' },
];

interface ConectaReportProps {
  showCalendar?: boolean;
  showDistributor?: boolean;
  showEnvironment?: boolean;
  showMySQL?: boolean;
  title?: string;
  reportMethod?: string;
}

const ConectaReport: React.FC<ConectaReportProps> = ({
  showCalendar = false,
  showDistributor = false,
  showEnvironment = false,
  showMySQL = false,
  title = '',
  reportMethod,
}) => {
  const [message, setMessage] = useState<string>('');
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedList, setSelectedList] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [allData, setAllData] = useState<any[]>([]);
  const [columns, setColumnns] = useState<any[]>([]);
  const [displayData, setDisplayData] = useState<any>({ columns: [], data: [] });
  const [exportPromise, setExportPromise] = useState<any>(null);
  const [downloadModal, setDownloadModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // Novo estado para indicar se a busca (requisição dos dados) já foi solicitada
  const [searched, setSearched] = useState(false);
  const isMultiDistributor = reportMethod !== 'getConectaPedidos';
  // Filtros
  const [environment, setEnvironment] = useState('UAT');
  const [selectedDistributors, setSelectedDistributors] = useState<any>(
    isMultiDistributor ? [] : null);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [sellersFilter, setSellersFilter] = useState<any[]>([]);
  

  

  // Estilos customizados para o react-select
  const customSelectStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      border: '1px solid #D1D5DB',
      borderRadius: '0.375rem',
      boxShadow: state.isFocused ? '0 0 0 1px #3B82F6' : 'none',
      '&:hover': {
        border: '1px solid #3B82F6',
      },
      minHeight: '38px',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#E0E7FF'
        : state.isFocused
        ? '#F3F4F6'
        : 'white',
      color: '#374151',
      cursor: 'pointer',
    }),
  };

  // Hook para resetar os estados quando a rota for alterada
  const location = useLocation();

  const resetState = () => {
    setMessage('');
    setConfirmModal(false);
    setSelectedList(null);
    setIsLoading(false);
    setIsLoaded(false);
    setAllData([]);
    setColumnns([]);
    setDisplayData({ columns: [], data: [] });
    setExportPromise(null);
    setDownloadModal(false);
    setSearchTerm('');
    setEnvironment('UAT');
    setSelectedDistributors(isMultiDistributor ? [] : null);
    setSelectedDate(new Date());
    setSearched(false);
  };

  useEffect(() => {
    // Sempre que a rota mudar, reseta os estados do componente
    resetState();
    if (!showCalendar && !showDistributor && !showEnvironment) {
        getData();
    }
  }, [location.pathname]);

  useEffect(() => {
    setConfirmModal(message !== '');
  }, [message]);

  useEffect(() => {
    setDownloadModal(exportPromise != null);
  }, [exportPromise]);

  // Atualiza os dados exibidos conforme a busca
  useEffect(() => {
    const filteredData = filterDataBySearch(allData, searchTerm);
    setDisplayData(filteredData.slice(0, limit));
  }, [searchTerm, allData]);

  useEffect(() => {
    if (showDistributor) {
      getSellersFilter();
    }
  }, [showDistributor]);

  // Se nenhum filtro estiver visível, executa a busca automaticamente
  useEffect(() => {
    
  }, [showCalendar, showDistributor, showEnvironment]);

  const getData = async () => {
    setIsLoaded(false);
    setIsLoading(true);
    try {
      const response = await getMethodResponse();
      const formattedData = formatDates(response.data);
      setAllData(formattedData);
      setColumnns(response.data.columns);
      setDisplayData(formattedData.slice(0, limit));
      setIsLoaded(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setSearched(true);
      setIsLoading(false);
    }
  };

  const getParams = () => {
    return {
      distributors: isMultiDistributor ? selectedDistributors.map((i:any) => i.value) : selectedDistributors ? selectedDistributors.value : null,
      environment,
      selectedDate,
    };
  };

  const getMethodResponse = async (exportReport:boolean = false, exportCSV:boolean = false, exportMySQL:boolean = false) => {
    const data:any = getParams();
    if (exportReport) data['export'] = true;
    if (exportCSV) data['exportCSV'] = true;
    if (exportMySQL) data['mysql'] = true;
    
    if (reportMethod === 'getConectaSellers') {
      return API.getConectaSellers(data);
    } else if (reportMethod === 'getConectaSellerGroup') {
      return API.getConectaSellerGroup(data);
    } else if (reportMethod === 'getConectaSellerMapping') {
      return API.getConectaSellerMapping(data);
    } else if (reportMethod === 'getConectaBlockListSeller') {
      return API.getConectaBlockListSeller(data);
    } else if (reportMethod === 'getConectaPedidos') {
      return API.getConectaPedidos(data);
    } else if (reportMethod === 'getConectaLojistaCustomer') {
      return API.getConectaLojistaCustomer(data);
    } else if (reportMethod === 'getConectaLojistaCompany') {
      return API.getConectaLojistaCompany(data);
    } else if (reportMethod === 'getConectaULPonderadas') {
      return API.getConectaULPonderadas(data);
    } else if (reportMethod === 'getConectaLojistaVIP') {
      return API.getConectaLojistaVIP(data);
    } else if (reportMethod === 'getConectaGrupoLojistaRoot') {
      return API.getConectaGrupoLojistaRoot(data);
    } else if (reportMethod === 'getConectaGrupoLojistaOpen') {
      return API.getConectaGrupoLojistaOpen(data);
    } else if (reportMethod === 'getConectaVendedor') {
      return API.getConectaVendedor(data);
    } else if (reportMethod === 'getConectaGrupoVendedor') {
      return API.getConectaGrupoVendedor(data);
    } else if (reportMethod === 'getConectaLimiteCredito') {
      return API.getConectaLimiteCredito(data);
    } else if (reportMethod === 'getConectaVoucherPrimario') {
      return API.getConectaVoucherPrimario(data);
    } else if (reportMethod === 'getConectaVoucherSecundario') {
      return API.getConectaVoucherSecundario(data);
    } else if (reportMethod === 'getConectaProduto') {
      return API.getConectaProduto(data);
    } else if (reportMethod === 'getConectaMarcas') {
      return API.getConectaMarcas(data);
    } else if (reportMethod === 'getConectaCategorias') {
      return API.getConectaCategorias(data);
    } else if (reportMethod === 'getConectaIndustrias') {
      return API.getConectaIndustrias(data);
    } else if (reportMethod === 'getConectaBlackListIndustria') {
      return API.getConectaBlackListIndustria(data);
    } else if (reportMethod === 'getDevITDistribuidores') {
      return API.getDevITDistribuidores(data);
    } else if (reportMethod === 'getDevITLojistas') {
      return API.getDevITLojistas(data);
    } else if (reportMethod === 'getDevITVendedores') {
      return API.getDevITVendedores(data);
    }else if (reportMethod === 'getFreshdeskLojistaCompany') {
      return API.getConectaFreshdeskLojistaCompany(data);
    } else if (reportMethod === 'getFreshdeskLojistaContact') {
      return API.getConectaFreshdeskLojistaContact(data);
    } else if (reportMethod === 'getFreshdeskVendedor') {
      return API.getConectaFreshdeskVendedor(data);
    } else if (reportMethod === 'getFreshdeskDistribuidor') {
      return API.getConectaFreshdeskDistribuidor(data);
    }
  };

  const getSellersFilter = async () => {
    const response = await API.getConectaSellersFilter();
    const data = response.data.map((item: any) => ({
      label: item.name,
      value: item.IDDistribuidor,
    }));
    setSellersFilter(data);
  };

  // Função para gerar relatório – abre o modal com preloader e, ao finalizar a promise, 
  // o DownloadModal exibirá o link para baixar o arquivo.
  const generateReport = async(exportType: string) => {
    const promise = getMethodResponse(true, exportType == 'csv', exportType == 'mysql');
    // Exemplo de chamada para gerar relatório; para simulação, usamos uma promise que resolve em 3 segundos
    /*const fakePromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve({ downloadUrl: `https://example.com/download/${exportType}` });
      }, 3000);
    });*/
    setExportPromise(promise);
  };

  const formatDates = (data: any) => {
    return data.data.map((row: any) => {
      for (let a = 0; a < row.length; a++) {
        if (isIsoDateString(row[a])) {
          row[a] = convertToBrazilianDate(row[a]);
        }
      }
      return row;
    });
  };

  const filterDataBySearch = (data: any[], term: string) => {
    if (!term) return data;
    const searchLower = term.toLowerCase();
    return data.filter((item: any) =>
      Object.values(item).some((value: any) =>
        value?.toString().toLowerCase().includes(searchLower)
      )
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">

        {/* Modais */}
      <Modal show={confirmModal} onHide={() => setMessage('')} centered>
        <ConfirmModal
          title={selectedList?.NomeLista}
          message={message}
          onClose={() => setMessage('')}
        />
      </Modal>


      {downloadModal && (
        <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
          <DownloadModal
            promise={exportPromise}
            onClose={() => setExportPromise(null)}
          />
        </div>
      )}
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        {/* Cabeçalho */}
        <div className="mb-8">
          <PageTitle>{title}</PageTitle>
          {searched && allData?.length > 0 && (
            <div className="mt-6 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              <StatsCard
                title="Total de Registros"
                value={allData.length}
                description={
                  allData.length > limit
                    ? `Mostrando ${limit} de ${allData.length} registros`
                    : 'Registros encontrados'
                }
                icon={<FileSpreadsheet className="w-6 h-6" />}
              />
              {searchTerm && (
                <StatsCard
                  title="Resultados da Busca"
                  value={filterDataBySearch(allData, searchTerm).length}
                  description="Registros encontrados com o termo pesquisado"
                  icon={<Search className="w-6 h-6" />}
                />
              )}
            </div>
          )}
        </div>

        {/* Seção de Filtros – renderiza somente se ao menos um filtro for visível */}
        {(showCalendar || showDistributor || showEnvironment) && (
          <div className="bg-white rounded-xl shadow border border-gray-200 p-6 mb-6">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">Filtros</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              {/* Filtro: Ambiente */}
              {showEnvironment && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Ambiente
                  </label>
                  <div className="flex space-x-4">
                    <label className="flex items-center space-x-2 cursor-pointer">
                      <input
                        type="radio"
                        value="UAT"
                        checked={environment === 'UAT'}
                        onChange={() => setEnvironment('UAT')}
                        className="form-radio text-blue-600"
                      />
                      <span className="text-gray-700">UAT</span>
                    </label>
                    <label className="flex items-center space-x-2 cursor-pointer">
                      <input
                        type="radio"
                        value="PRD"
                        checked={environment === 'PRD'}
                        onChange={() => setEnvironment('PRD')}
                        className="form-radio text-blue-600"
                      />
                      <span className="text-gray-700">PRD</span>
                    </label>
                  </div>
                </div>
              )}

              {/* Filtro: Período */}
              {showCalendar && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Período
                  </label>
                  <div className="relative">
                    <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date: Date) => setSelectedDate(date)}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring focus:ring-blue-200"
                    />
                  </div>
                </div>
              )}

              {/* Filtro: Distribuidores */}
              {showDistributor && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Distribuidores
                  </label>
                  <Select
                    isMulti={isMultiDistributor}
                    value={selectedDistributors}
                    onChange={
                        isMultiDistributor
                          ? (newValue) =>
                              setSelectedDistributors(
                                newValue ? [...newValue] : []
                              )
                          : (newValue) => setSelectedDistributors(newValue)
                      }
                    options={sellersFilter}
                    placeholder="Selecione os distribuidores"
                    styles={customSelectStyles}
                    className="text-sm"
                  />
                </div>
              )}
            </div>

            {/* Botão de Filtrar */}
            <div className="mt-6 flex justify-end">
              <button
                onClick={getData}
                className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors inline-flex items-center gap-2"
              >
                <Filter className="w-5 h-5" />
                Filtrar
              </button>
            </div>
          </div>
        )}

        {/* Bloco de Busca, Exportação e Resultados – somente exibido após a requisição (searched === true) */}
        {(isLoading || isLoaded) && (
          <>
            <div className="mb-6 flex flex-col sm:flex-row justify-between items-center gap-4">
              
                <SearchInput
                    value={searchTerm}
                    onChange={setSearchTerm}
                    placeholder="Buscar em todos os registros..."
                />
                { showMySQL ? <>
                <button
                    onClick={() => generateReport('mysql')}
                    className="w-full sm:w-auto px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center gap-2 shadow-sm hover:shadow"
                >
                    <FileSpreadsheet className="w-5 h-5" />
                    Exportar Query
                </button></> 
                : null }
              <button
                onClick={() => generateReport('csv')}
                className="w-full sm:w-auto px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center gap-2 shadow-sm hover:shadow"
              >
                <FileSpreadsheet className="w-5 h-5" />
                Exportar CSV
              </button>
              <button
                onClick={() => generateReport('planilha')}
                className="w-full sm:w-auto px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-200 flex items-center justify-center gap-2 shadow-sm hover:shadow"
              >
                <FileSpreadsheet className="w-5 h-5" />
                Exportar Planilha
              </button>
            </div>

            <div className="bg-white rounded-xl shadow border border-gray-200 overflow-hidden">
              {isLoading ? (
                <div className="flex flex-col items-center justify-center py-12">
                  <Loader2 className="w-8 h-8 text-blue-500 animate-spin" />
                  <p className="mt-4 text-gray-500">Carregando dados...</p>
                </div>
              ) : !allData.length ? (
                <div className="flex flex-col items-center justify-center py-12">
                  <div className="bg-gray-100 rounded-full p-3">
                    <FileSpreadsheet className="w-8 h-8 text-gray-400" />
                  </div>
                  <p className="mt-4 text-gray-500">Nenhum registro encontrado</p>
                </div>
              ) : (
                <DataTable data={displayData} columns={columns} />
              )}
            </div>
          </>
        )}
      </div>

      
    </div>
  );
};

// Funções auxiliares
const isIsoDateString = (value: any) => {
  const isoDateRegex =
    /^\d{4}-\d{2}-\d{2}(?:[ T]\d{2}:\d{2}:\d{2}(?:\.\d{3}Z)?)?$/;
  return isoDateRegex.test(value);
};

const convertToBrazilianDate = (isoDate: string) => {
  if (typeof isoDate !== 'string') return null;
  // Separa a parte da data (antes do "T" ou espaço)
  const [datePart] =
    isoDate.split('T').length > 1 ? isoDate.split('T') : isoDate.split(' ');
  const [year, month, day] = datePart.split('-');
  return `${day}/${month}/${year}`;
};

export default ConectaReport;