import React from 'react';
import { Phone } from 'lucide-react';

type PhoneInputProps = {
  value: string;
  onChange: (value: string) => void;
};

export function PhoneInput({ value, onChange }: PhoneInputProps) {
  const formatPhoneNumber = (input: string) => {
    // Remove non-numeric characters
    const numbers = input.replace(/\D/g, '');
    
    // Format as (XX) XXXXX-XXXX
    if (numbers.length <= 11) {
      return numbers.replace(/(\d{2})?(\d{5})?(\d{4})?/, (_, ddd, first, second) => {
        let formatted = '';
        if (ddd) formatted += `(${ddd}`;
        if (first) formatted += `) ${first}`;
        if (second) formatted += `-${second}`;
        return formatted;
      });
    }
    
    return input;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPhoneNumber(e.target.value);
    onChange(formatted);
  };

  return (
    <div className="relative">
      <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
      <input
        type="text"
        value={value}
        onChange={handleChange}
        placeholder="(00) 00000-0000"
        className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        maxLength={15}
      />
    </div>
  );
}