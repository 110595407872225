import { Table } from './Table';

const columns = [
  { key: 'date', label: 'Data' },
  { key: 'status', label: 'Status' },
  { key: 'type', label: 'Tipo' },
  { key: 'region', label: 'Região' },
  { key: 'value', label: 'Valor' }
];

export const DataTable = ({ data, columns }: { data: any, columns:any }) => (
  <div className="overflow-x-auto">
    <Table columns={columns} data={data} />
  </div>
);